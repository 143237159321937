import React from "react"
import { Typography, Card } from "antd"
import { Animated } from "react-animated-css"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/Layout"
import TrustedBy from "../../components/TrustedBy"
import DevelopmentProcess from "../../components/WEB_DevelopmentProcess"
import SideForm from "./../../components/SideForm"
import behindImageSmall from "../../images/Group 495@2x.png"
import manual_testing from '../../images/manual_testing-01.png'
import mobile_testing from '../../images/mobile_testing-01.png'
import saas_testing from '../../images/saas_testing-01.png'
import usability_testing from '../../images/usability_testing-01.png'
import web_testing from '../../images/web_testing-01.png'
import ServiceCard from "../../components/ServiceCard"
import ServiceStepsProcess from "../../components/ServiceStepsProcess"

import "../../styles/services.scss"
import "../../styles/animate.css"

const { Title } = Typography

const Index = ({ data }) => {
  const backgroundImage = data.bgImage.childImageSharp.fluid.src
  return (
    <Layout className="services-page-container" data={data} 
    pageTitle={"Visiomate - Quality Assurance Services"}
    descrip = {"Ensure flawless software with our comprehensive quality assurance services. Our expert QA team delivers reliable testing and bug-free solutions. Learn more!"}>
      <div className="top-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="logo">
          <Link to="/" >
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>

        <div className="box-wrapper text-and-form-container">
          <div className="information">
            <Animated
              animationIn="fadeInUp"
              animationInDuration={500}
              animationInDelay={300}
              isVisible={true}
            >
              <Title>We Offer Manual and Automated Testing Services</Title>
              <p>
                We provide effective Manual and automated testing solutions,
                which help our customers to make their software reliable. <br />
                <br />
                We offer a wide range of testing activities from test planning,
                test cases’ design and execution, to test reporting and QA
                process improvements.
              </p>
              <TrustedBy showHeading={false} />
            </Animated>
          </div>
          <Animated
            animationIn="fadeInDown"
            animationInDuration={800}
            animationInDelay={500}
            isVisible={true}
            className="form"
          >
            <SideForm />
          </Animated>
        </div>
      </div>
      <div className="services-description-section">
      <div className="service-heading-and-detail">
        <Title>Quality Assurance Services</Title>
      </div>
      <div className="service-cards-wrapper">
        <ServiceCard img_src={manual_testing} img_alt={'manual_testing'} card_heading={'Manual Software Testing'} card_para={'A software testing approach where testers manually evaluate the functionality and quality of an application without the use of automated tools.'}/>
        <ServiceCard img_src={usability_testing} img_alt={'usability_testing'} card_heading={'Usability Testing'} card_para={'A method used to evaluate how easy and intuitive a product—such as a website or mobile app—is for real users. Evaluate a product by testing it on users to assess design intuitiveness.'}/>
        <ServiceCard img_src={web_testing} img_alt={'web_testing'} card_heading={'Web App Testing'} card_para={'A comprehensive evaluation process aimed at identifying issues, defects, and bugs in web applications before they are deployed to users. Including functionality, usability, performance, and security, across different devices and operating systems.'}/>
        <ServiceCard img_src={mobile_testing} img_alt={'mobile_testing'} card_heading={'Mobile App Testing'} card_para={'The process of evaluating mobile applications to ensure they function correctly, are user-friendly, and meet quality standards. Including functionality, usability, performance, and security, across different devices and operating systems.'}/>
        <ServiceCard img_src={saas_testing} img_alt={'saas_testing'} card_heading={'SaaS Software Testing'} card_para={'A specialized process designed to evaluate the functionality, performance, security, and usability of cloud-based applications. SaaS applications are hosted on the cloud and accessed via the internet, which introduces unique challenges and requirements for testing.'}/>
        <ServiceCard card_heading={'Let connect, book a free consultancy session with our experts'} card_button={"Free Consultancy"}/>
      </div>
        <div className="behindimagediv">
            <img
              className="behindImageSmall"
              src={behindImageSmall}
              alt="yellow-triangle"
            />
            <img className="behindImageLarg" src={behindImageSmall} alt="yellow-triangle" />
        </div>
      </div>
      <DevelopmentProcess title='Our Testing Services Process' >
        <ServiceStepsProcess process_heading='Discovery' process_para="Establishing the scope, objectives, resources, and schedule for testing activities. Creating a test plan document that outlines strategies, entry and exit criteria, and evaluation metrics." process_step_number="1" process_indentify="odd" />
        <ServiceStepsProcess process_heading='Test Case Development' process_para="Designing specific test cases and scenarios based on requirements and specifications. Writing detailed test scripts that describe inputs, execution steps, and expected outcomes." process_step_number="2" />
        <ServiceStepsProcess process_heading='Environment Setup' process_para="Preparing the necessary hardware and software environments for testing. Configuring servers, databases, and any required tools to mimic real-world conditions." process_step_number="3" process_indentify="odd" iconShow="dark"/>
        <ServiceStepsProcess process_heading='Test Execution' process_para="Running the developed test cases against the application. Documenting results, noting any deviations from expected outcomes, and identifying defects." process_step_number="4" iconShow="dark" />
        <ServiceStepsProcess process_heading='Defect Reporting' process_para="Logging any issues or bugs discovered during testing. Using defect tracking tools (e.g., Jira) to report bugs with detailed descriptions, severity levels, and reproduction steps." process_step_number="5" process_indentify="odd" />
        <ServiceStepsProcess process_heading='User Acceptance Testing (UAT)' process_para="Final validation by end-users to ensure the application meets their needs. Gathering feedback from users to confirm that the application is ready for production." process_step_number="6" />
      </DevelopmentProcess>
    </Layout>
  )
}

export default Index

export const softwareTestingQuery = graphql`
  query softwareTestingQuery {
    FarmManagementSystem: file(
      relativePath: { eq: "FarmManagementSystem/pf.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ISOComplianceManagement: file(
      relativePath: { eq: "ISOComplianceManagement/iso.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TenderAssetRecordManagement: file(
      relativePath: { eq: "TenderAssetRecordManagement/tender.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ViztosPos: file(relativePath: { eq: "ViztosPos/vistos_image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo1: file(relativePath: { eq: "Odoo/odoo1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo2: file(relativePath: { eq: "Odoo/odoo2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo3: file(relativePath: { eq: "Odoo/odoo3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Custom: file(relativePath: { eq: "Custom/CustDevelopment.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365: file(relativePath: { eq: "D365/D365.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365_2: file(relativePath: { eq: "D365/D365_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Support: file(relativePath: { eq: "safty.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis: file(relativePath: { eq: "analysis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis2: file(relativePath: { eq: "analysis2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Development: file(relativePath: { eq: "development.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Data: file(relativePath: { eq: "data.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage: file(relativePath: { eq: "Mask_group8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
